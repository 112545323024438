.Search {
    width: 100%;
    min-height: 100vh;
    padding: 24px;
    margin-top: 56px;
}
.search-list {
    display: flex;
    margin-bottom: 15px;
}
.title-descrip {
    margin-left: 15px;
    width: 50%;
}
.title-descrip>h3 {
    font-size: 19px ;
    font-weight: 600;
    margin-top: 3px;
}
#search-views {
    font-weight: 300;
    line-height: 1.7rem;
    text-transform: none;
    color: grey;
}
#search-auth{
    font-weight: 300;
    line-height: 1.7rem;
    text-transform: none;
    color: grey;
    margin-right: 20px;
}
.what-what {
    display: flex;
}
#search-descrip {
    
    font-size: .9rem;
    font-weight: 600;
    line-height: 1.4rem;
    text-transform: none;
    color: grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#vids-dur {
    padding: 1px 0 1px 3px;
    border-radius: 2px;
    font-weight: bold;
    color: white;
    width: 35px;
    background: grey;
    opacity: .8;
    position: relative;
    top: 23px;
    right: 55px;
}