body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	font-family: 'Roboto', sans-serif;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
button {
	background: none;
}
button:focus{
	outline: none;
}
input:focus{
	outline: none;
}
a {
	text-decoration: none;
	color: black;
}
button:hover {
	cursor: pointer;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.App {
	font-family: 'Roboto', sans-serif;
	background: rgb(250,250,250);
	min-height: 100vh;
}
* {
	box-sizing: border-box;
}

.videoz > h4 {
    font-size: 16px;
    font-weight: bold;
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#vid-author {
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.8rem;
    text-transform: none;
    color: grey;
}
#view-count{
    font-size: .8rem;
    font-weight: 400;
    line-height: .4rem;
    text-transform: none;
    color: grey;
}
video {
    width: 210px;
    height: 117px;
}
.Home {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
}

.recommended {
    /* position: relative; */
    /* right: 472px; */
    /* left: -472px; */
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
}
.video-container{
    display: flex;
    flex-wrap: wrap;
    width: 87vw;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-bottom: 50px;
}
.videoz {
    width: 20%;
    margin-bottom: 20px;
}
#thumbnail {
    background: black;
}
#vid-duration {
    padding: 1px 0 1px 3px;
    border-radius: 2px;
    font-weight: bold;
    color: white;
    width: 35px;
    background: grey;
    opacity: .8;
    position: relative;
    bottom: 78px;
    left: 170px;
}
.channel {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
}

.channel-banner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 143px;
    background-color: rgb(212, 212, 212);
}

.channel-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 143px;
}

.left-channel-banner {
    display: flex;
    flex-direction: row;
}

.name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 30px;
}

.profile-image {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.name-wrapper > .user-name{
    font-size: 1.4rem;
    border: none;
}

.name-wrapper > .channel-name {
    font-size: 1.9rem;
}

.name-wrapper > .channel-name:disabled {
    background-color: rgb(212, 212, 212);
    border: none;
    color: rebeccapurple;
    font-weight: 700;
}

.add-channel-name {
    background-color: rebeccapurple;
    color: whitesmoke;
    font-size: 16px;
    height: 37px;
    width: 193px;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-channel-name:hover{
    -webkit-transition: 0.5;
    transition: 0.5;
    background-color: rgb(110, 66, 155);
    cursor: pointer;
}

.add-channel-name:active{
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-transition: 1;
    transition: 1;
}

.Home {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.videoz > h4 {
    font-size: 16px;
    font-weight: bold;
}

#view-count{
    font-size: .8rem;
    font-weight: 400;
    line-height: .4rem;
    text-transform: none;
    color: grey;
}

video {
    width: 210px;
    height: 117px;
}

.uploads-view {
    /* position: relative; */
    /* right: 472px; */
    /* left: -472px; */
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
}
.video-container{
    display: flex;
    flex-wrap: wrap;
    width: 87vw;
    border-bottom: 1px grey solid;
}
.videoz {
    width: 20%;
    margin-bottom: 20px;
}
#thumbnail {
    background: black;
}

.delete-btn {
    float: right;
    margin-right: 44px;
    margin-top: 15px;
    border: none;
    border-radius: 2px;
    background-color: rebeccapurple;
    color: whitesmoke;
}

.upload-page-video-info {
    display: inline-block;
}
.Search {
    width: 100%;
    min-height: 100vh;
    padding: 24px;
    margin-top: 56px;
}
.search-list {
    display: flex;
    margin-bottom: 15px;
}
.title-descrip {
    margin-left: 15px;
    width: 50%;
}
.title-descrip>h3 {
    font-size: 19px ;
    font-weight: 600;
    margin-top: 3px;
}
#search-views {
    font-weight: 300;
    line-height: 1.7rem;
    text-transform: none;
    color: grey;
}
#search-auth{
    font-weight: 300;
    line-height: 1.7rem;
    text-transform: none;
    color: grey;
    margin-right: 20px;
}
.what-what {
    display: flex;
}
#search-descrip {
    
    font-size: .9rem;
    font-weight: 600;
    line-height: 1.4rem;
    text-transform: none;
    color: grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#vids-dur {
    padding: 1px 0 1px 3px;
    border-radius: 2px;
    font-weight: bold;
    color: white;
    width: 35px;
    background: grey;
    opacity: .8;
    position: relative;
    top: 23px;
    right: 55px;
}
.Video {
    display: flex;
    background: rgb(250,250,250);
    min-height: 100vh;
    margin-top: 56px;
}
.vid{
    width: 763px;
    height: 428px;
}
#xxxx {
    font-weight: 300;
    line-height: 1.7rem;
}
.player{
    margin-left: 24px;
    padding: 24px 0 0 24px;
    width: 770px;
    min-height: 100vh;
}
#titulo {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 8px 0 0;
}
.views-n-likes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* border: 1px solid green; */
}
.likes {
    display: flex;
    /* position: absolute;
    right: 500px; */
}
.likebox {
    display: flex;
    align-items: center;
    color: grey;
}
.dislikebox {
    display: flex;
    align-items: center;
    color: grey;
    margin-left: 10px;
    
}
#like-button{
    border: none;
    
}
#dislike-button{
    border: none;
}
.category-list {
    /* min-height: 1000px; */
    width: 426px;
    /* border: 1px solid red; */
    margin: 24px 0 0 38px;
    background: rgb(250,250,250);
}
.suggested-list {
    display: flex;
    height: 135px;
    width: 95%;
    /* border: 1px solid blue; */
}
.suggested-list>video {
    height: 117px;
    width: 210px;
}
.category-desc {
    height: 117px;
    width: 208px;
    padding-right: 24px;
    margin-left: 7px;
    /* border: 1px solid purple; */
}
.category-desc>h4{
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
}
#sug-auth { 
    font-weight: 300;
    line-height: 1.7rem;
    text-transform: none;
    color: grey;
    font-size: .9rem;
}
#sug-v-count {
    font-weight: 300;
    line-height: 1.1rem;
    text-transform: none;
    color: grey;
    font-size: .9rem;
}
.upperTier {
    display: flex;
}
.author-area {
    margin-left: 15px;
    margin-top: 5px;
}
#line-thing {
    border-bottom: 1px solid rgb(206, 206, 206);
    margin: 15px 0;
}
.vid-description {
    margin-left: 65px;
}
#author-text {
    font-weight: bold;
}
#vids-duration {
    padding: 1px 0 1px 3px;
    border-radius: 2px;
    font-weight: bold;
    color: white;
    width: 35px;
    background: grey;
    opacity: .8;
    position: relative;
    top: 33px;
    right: 48px;
}

.comments {
    background: rgb(250,250,250);
}
.comments-main {
    display: flex;
    margin-bottom: 20px;
    width: 90%;
}
#com-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%
}
.author-n-com {
    margin-left: 10px;
    width: 90%;
}
#com-author {
    font-weight: bold;
    line-height: 1.7rem;
}
#comment-field {
    width: 90%;
    height: 25px;
    border: none;
    font-size: 14px;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-left: 10px;
    background: rgb(250,250,250);
}
.add-comment {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 20px;
}
#com-delete {
    width: 110px;
    background: rebeccapurple;
    color: white;
    margin-top: 5px;
    border-radius: 2px;
    border: none;
}
.comment-area {
    max-width: 100%;
    height: auto;
}
#button-add-com {
    position: relative;
    left: 630px;
    bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
}
.log-comment {
    position: relative;
    left: 320px;
    bottom: 20px;
}
#log-com-button {
    width: 160px;
    padding: 5px;
    background: rebeccapurple;
    color: white;
    border-radius: 2px;
    border: none;
}
.upload-page {
    /* height: 94%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 56px;
    min-height: 100vh;
    background: rgb(250,250,250);
}
.upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: 'Roboto', sans-serif;
    background-color: rgb(212, 212, 212);
    width: 45%;
    /* margin-top: 20px;
    margin-left: 25%; */
    border-radius: 1%;
    border-style: solid;
    margin: 10px 0;
}

.upload-section > video {
    border: 1px solid rgb(175, 175, 175);
    margin: 30px 0px;
    background-color: whitesmoke;
}

.upload-area {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: whitesmoke;
}

.upload-box{
    box-sizing: border-box;
    height: 99%;
    width: 99%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload-box > #upload-icon-btn {
    height: 130px;
    width: 160px;
    margin-bottom: 15px;
}

.upload-box > img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-transition: 0.1;
    transition: 0.1;
    opacity: 0.8;
}

.upload-form {
    box-sizing: border-box;
    width: 40vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 300px;
    margin-bottom: 15px;
}

.upload-form > select, input, textarea {
    font-family: 'Roboto', sans-serif;
    margin: 5px 0px;
    box-sizing: border-box;
}

.upload-form > .category, .title, .description {
    width: 99%;
}

.upload-form > textarea {
    height: 100px;
}

.upload-form > .submit {
    width: 90%;
    box-shadow: 7px 5px 15px 5px rgba(175, 175, 175, 1);
    background-color: rebeccapurple;
    color: whitesmoke;
    font-size: 16px;
    height: 37px;
    width: 193px;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-form > .submit:hover{
    -webkit-transition: 0.5;
    transition: 0.5;
    background-color: rgb(110, 66, 155);
    cursor: pointer;
}


.nav{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    height: 56px;
    /* border-bottom: 1px solid gray; */
    padding: 0 16px;
    background: rgb(255, 255, 255);
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
}
.hamburger{
    border: none;
}
#icon {
    color: rebeccapurple;
    font-size: 24px;
}
.search-button {
    background: rgb(245, 245, 245);
    width: 65px;
    height: 30px;
    border-radius: 0 2px 2px 0; 
    border: 1px solid rgb(206, 206, 206);
    
}
#menu {
    font-size: 19px;
}
.menu2 {
    width: 40px;
    height:40 px;
    margin-right: 16px;
}
#mag-glass{
    width: 20px;
    height: 20px;
    /* padding: 1px 6px; */
}
#upload-icon{
    width: 22px;
    height: 22px;
}
.utube{
    font-weight: bold;
    font-size: 20px;
}
#search-field {
    width: 536px;
    height: 30px;
    border-radius: 1px 0 0 1px;
    border: 1px solid rgb(206, 206, 206);
    font-size: 15px;
    
}
#youtube {
    display: flex;
    align-items: center;
    margin-right: 50px;
}
::-webkit-input-placeholder {
    font-size: 16px;
    padding-left: 5px;
}
::-ms-input-placeholder {
    font-size: 16px;
    padding-left: 5px;
}
::placeholder {
    font-size: 16px;
    padding-left: 5px;
}
.add-video{
    border: none;
}
#search{
    display: flex;
    align-items: center;
    margin: 0 60px;
}
#buttons{
    display: flex;
    align-items: center;
}
.user-btn {  
    border: none;
}
.user-image {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
}
.sign-in{
    border: none;
    color: yellowgreen;
    font-weight: bold;
    font-size: 14.5px;
    margin-left: 10px;
}
.sign-out-btn {
    border: none;
    color: yellowgreen;
    font-weight: bold;
    font-size: 14.5px;
}
.user-menu{
    position: absolute;
    margin-top: 9px;
    width: 300px;
    height: 170px;
    background: white;
    z-index: 100;
    box-shadow: 2px 2px 5px rgb(203, 203, 203);
}

.user-account{
    display: flex;
    width: 100%;
    height: 72px;
    background: rgb(238, 238, 238)
}
.user-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100px;
}

.menu-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}

.user-account-text {
    width: 100%;
}

.user-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 5px;
    width: 99%;
    height: 40%;
}

.user-email {
    padding-top: 2px;
    width: 99%;
    height: 40%;
}

.menu-channel {
    display: flex;
    align-items: center;
    margin-top: 5px;
    width: 100%;
    height: 40px;
}
.menu-sign-out {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
}

.menu-channel:hover {
    cursor: pointer;
    background: rgb(238, 238, 238)
}

.menu-sign-out:hover {
    cursor: pointer;
    background: rgb(238, 238, 238)
}

.menu-icon {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 40px;
    height: 30px;
    padding-left: 10px;
    padding-right: 25px;
}

.menu-icon:hover {
    cursor: pointer;
}

.swal2-popup .swal2-styled.swal2-confirm {
    background-color: rebeccapurple !important;
}
