.channel {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
}

.channel-banner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 143px;
    background-color: rgb(212, 212, 212);
}

.channel-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 143px;
}

.left-channel-banner {
    display: flex;
    flex-direction: row;
}

.name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 30px;
}

.profile-image {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.name-wrapper > .user-name{
    font-size: 1.4rem;
    border: none;
}

.name-wrapper > .channel-name {
    font-size: 1.9rem;
}

.name-wrapper > .channel-name:disabled {
    background-color: rgb(212, 212, 212);
    border: none;
    color: rebeccapurple;
    font-weight: 700;
}

.add-channel-name {
    background-color: rebeccapurple;
    color: whitesmoke;
    font-size: 16px;
    height: 37px;
    width: 193px;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-channel-name:hover{
    transition: 0.5;
    background-color: rgb(110, 66, 155);
    cursor: pointer;
}

.add-channel-name:active{
    transform: scale(0.95);
    transition: 1;
}

.Home {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.videoz > h4 {
    font-size: 16px;
    font-weight: bold;
}

#view-count{
    font-size: .8rem;
    font-weight: 400;
    line-height: .4rem;
    text-transform: none;
    color: grey;
}

video {
    width: 210px;
    height: 117px;
}

.uploads-view {
    /* position: relative; */
    /* right: 472px; */
    /* left: -472px; */
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
}
.video-container{
    display: flex;
    flex-wrap: wrap;
    width: 87vw;
    border-bottom: 1px grey solid;
}
.videoz {
    width: 20%;
    margin-bottom: 20px;
}
#thumbnail {
    background: black;
}

.delete-btn {
    float: right;
    margin-right: 44px;
    margin-top: 15px;
    border: none;
    border-radius: 2px;
    background-color: rebeccapurple;
    color: whitesmoke;
}

.upload-page-video-info {
    display: inline-block;
}