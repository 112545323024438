.videoz > h4 {
    font-size: 16px;
    font-weight: bold;
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#vid-author {
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.8rem;
    text-transform: none;
    color: grey;
}
#view-count{
    font-size: .8rem;
    font-weight: 400;
    line-height: .4rem;
    text-transform: none;
    color: grey;
}
video {
    width: 210px;
    height: 117px;
}
.Home {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
}

.recommended {
    /* position: relative; */
    /* right: 472px; */
    /* left: -472px; */
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
}
.video-container{
    display: flex;
    flex-wrap: wrap;
    width: 87vw;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-bottom: 50px;
}
.videoz {
    width: 20%;
    margin-bottom: 20px;
}
#thumbnail {
    background: black;
}
#vid-duration {
    padding: 1px 0 1px 3px;
    border-radius: 2px;
    font-weight: bold;
    color: white;
    width: 35px;
    background: grey;
    opacity: .8;
    position: relative;
    bottom: 78px;
    left: 170px;
}