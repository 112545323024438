.upload-page {
    /* height: 94%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 56px;
    min-height: 100vh;
    background: rgb(250,250,250);
}
.upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: 'Roboto', sans-serif;
    background-color: rgb(212, 212, 212);
    width: 45%;
    /* margin-top: 20px;
    margin-left: 25%; */
    border-radius: 1%;
    border-style: solid;
    margin: 10px 0;
}

.upload-section > video {
    border: 1px solid rgb(175, 175, 175);
    margin: 30px 0px;
    background-color: whitesmoke;
}

.upload-area {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: whitesmoke;
}

.upload-box{
    box-sizing: border-box;
    height: 99%;
    width: 99%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload-box > #upload-icon-btn {
    height: 130px;
    width: 160px;
    margin-bottom: 15px;
}

.upload-box > img:hover {
    transform: scale(1.05);
    transition: 0.1;
    opacity: 0.8;
}

.upload-form {
    box-sizing: border-box;
    width: 40vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 300px;
    margin-bottom: 15px;
}

.upload-form > select, input, textarea {
    font-family: 'Roboto', sans-serif;
    margin: 5px 0px;
    box-sizing: border-box;
}

.upload-form > .category, .title, .description {
    width: 99%;
}

.upload-form > textarea {
    height: 100px;
}

.upload-form > .submit {
    width: 90%;
    box-shadow: 7px 5px 15px 5px rgba(175, 175, 175, 1);
    background-color: rebeccapurple;
    color: whitesmoke;
    font-size: 16px;
    height: 37px;
    width: 193px;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-form > .submit:hover{
    transition: 0.5;
    background-color: rgb(110, 66, 155);
    cursor: pointer;
}

