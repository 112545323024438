.nav{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    height: 56px;
    /* border-bottom: 1px solid gray; */
    padding: 0 16px;
    background: rgb(255, 255, 255);
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
}
.hamburger{
    border: none;
}
#icon {
    color: rebeccapurple;
    font-size: 24px;
}
.search-button {
    background: rgb(245, 245, 245);
    width: 65px;
    height: 30px;
    border-radius: 0 2px 2px 0; 
    border: 1px solid rgb(206, 206, 206);
    
}
#menu {
    font-size: 19px;
}
.menu2 {
    width: 40px;
    height:40 px;
    margin-right: 16px;
}
#mag-glass{
    width: 20px;
    height: 20px;
    /* padding: 1px 6px; */
}
#upload-icon{
    width: 22px;
    height: 22px;
}
.utube{
    font-weight: bold;
    font-size: 20px;
}
#search-field {
    width: 536px;
    height: 30px;
    border-radius: 1px 0 0 1px;
    border: 1px solid rgb(206, 206, 206);
    font-size: 15px;
    
}
#youtube {
    display: flex;
    align-items: center;
    margin-right: 50px;
}
::placeholder {
    font-size: 16px;
    padding-left: 5px;
}
.add-video{
    border: none;
}
#search{
    display: flex;
    align-items: center;
    margin: 0 60px;
}
#buttons{
    display: flex;
    align-items: center;
}
.user-btn {  
    border: none;
}
.user-image {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
}
.sign-in{
    border: none;
    color: yellowgreen;
    font-weight: bold;
    font-size: 14.5px;
    margin-left: 10px;
}
.sign-out-btn {
    border: none;
    color: yellowgreen;
    font-weight: bold;
    font-size: 14.5px;
}
.user-menu{
    position: absolute;
    margin-top: 9px;
    width: 300px;
    height: 170px;
    background: white;
    z-index: 100;
    box-shadow: 2px 2px 5px rgb(203, 203, 203);
}

.user-account{
    display: flex;
    width: 100%;
    height: 72px;
    background: rgb(238, 238, 238)
}
.user-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100px;
}

.menu-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}

.user-account-text {
    width: 100%;
}

.user-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 5px;
    width: 99%;
    height: 40%;
}

.user-email {
    padding-top: 2px;
    width: 99%;
    height: 40%;
}

.menu-channel {
    display: flex;
    align-items: center;
    margin-top: 5px;
    width: 100%;
    height: 40px;
}
.menu-sign-out {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
}

.menu-channel:hover {
    cursor: pointer;
    background: rgb(238, 238, 238)
}

.menu-sign-out:hover {
    cursor: pointer;
    background: rgb(238, 238, 238)
}

.menu-icon {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 40px;
    height: 30px;
    padding-left: 10px;
    padding-right: 25px;
}

.menu-icon:hover {
    cursor: pointer;
}

.swal2-popup .swal2-styled.swal2-confirm {
    background-color: rebeccapurple !important;
}