.Video {
    display: flex;
    background: rgb(250,250,250);
    min-height: 100vh;
    margin-top: 56px;
}
.vid{
    width: 763px;
    height: 428px;
}
#xxxx {
    font-weight: 300;
    line-height: 1.7rem;
}
.player{
    margin-left: 24px;
    padding: 24px 0 0 24px;
    width: 770px;
    min-height: 100vh;
}
#titulo {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 8px 0 0;
}
.views-n-likes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* border: 1px solid green; */
}
.likes {
    display: flex;
    /* position: absolute;
    right: 500px; */
}
.likebox {
    display: flex;
    align-items: center;
    color: grey;
}
.dislikebox {
    display: flex;
    align-items: center;
    color: grey;
    margin-left: 10px;
    
}
#like-button{
    border: none;
    
}
#dislike-button{
    border: none;
}
.category-list {
    /* min-height: 1000px; */
    width: 426px;
    /* border: 1px solid red; */
    margin: 24px 0 0 38px;
    background: rgb(250,250,250);
}
.suggested-list {
    display: flex;
    height: 135px;
    width: 95%;
    /* border: 1px solid blue; */
}
.suggested-list>video {
    height: 117px;
    width: 210px;
}
.category-desc {
    height: 117px;
    width: 208px;
    padding-right: 24px;
    margin-left: 7px;
    /* border: 1px solid purple; */
}
.category-desc>h4{
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
}
#sug-auth { 
    font-weight: 300;
    line-height: 1.7rem;
    text-transform: none;
    color: grey;
    font-size: .9rem;
}
#sug-v-count {
    font-weight: 300;
    line-height: 1.1rem;
    text-transform: none;
    color: grey;
    font-size: .9rem;
}
.upperTier {
    display: flex;
}
.author-area {
    margin-left: 15px;
    margin-top: 5px;
}
#line-thing {
    border-bottom: 1px solid rgb(206, 206, 206);
    margin: 15px 0;
}
.vid-description {
    margin-left: 65px;
}
#author-text {
    font-weight: bold;
}
#vids-duration {
    padding: 1px 0 1px 3px;
    border-radius: 2px;
    font-weight: bold;
    color: white;
    width: 35px;
    background: grey;
    opacity: .8;
    position: relative;
    top: 33px;
    right: 48px;
}
