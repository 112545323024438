.comments {
    background: rgb(250,250,250);
}
.comments-main {
    display: flex;
    margin-bottom: 20px;
    width: 90%;
}
#com-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%
}
.author-n-com {
    margin-left: 10px;
    width: 90%;
}
#com-author {
    font-weight: bold;
    line-height: 1.7rem;
}
#comment-field {
    width: 90%;
    height: 25px;
    border: none;
    font-size: 14px;
    border-bottom: 1px solid rgb(206, 206, 206);
    margin-left: 10px;
    background: rgb(250,250,250);
}
.add-comment {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 20px;
}
#com-delete {
    width: 110px;
    background: rebeccapurple;
    color: white;
    margin-top: 5px;
    border-radius: 2px;
    border: none;
}
.comment-area {
    max-width: 100%;
    height: auto;
}
#button-add-com {
    position: relative;
    left: 630px;
    bottom: 10px;
    border-radius: 2px;
    font-weight: bold;
}
.log-comment {
    position: relative;
    left: 320px;
    bottom: 20px;
}
#log-com-button {
    width: 160px;
    padding: 5px;
    background: rebeccapurple;
    color: white;
    border-radius: 2px;
    border: none;
}